import React, { useState, useEffect } from "react";
import "./UserInplay.css";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";
import useGetLiveMatch from "./hooks/useGetLiveMatch";
import { dateTimeFormatter } from "../utils/dateFormatter";
// import { useGetUserPlayedMatchBets } from "./hooks/useGetUserPlayedMatchBets";
// import { useGetUserPlayedMatchBetsByMatchId } from "./hooks/useGetUserPlayedMatchBetsByMatchId";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTv } from "@fortawesome/free-solid-svg-icons";
import useGetAccountStatus from "./hooks/useGetAccountStatus";

export default function UserInplay() {
  const navigate = useNavigate();
  let userId = sessionStorage.getItem("UUID");
  let userData = JSON.parse(sessionStorage.getItem("DATA")).data;

  const uuid = sessionStorage.getItem("UUID");
  const { mutate: accountStatus } = useGetAccountStatus();

  const { mutate: getLiveMatch } = useGetLiveMatch();

  const [liveMatches, setLiveMatches] = useState([]);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: "user",
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        console.log(error);
        // toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData) {
      const payload = {
        user_id: userId,
      };

      getLiveMatch(payload, {
        onSuccess: (response) => {
          let matchData = response?.data?.data;
          matchData = matchData.sort((a, b) => {
            return new Date(a.server) - new Date(b.server);
          });
          setLiveMatches(matchData);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startBet = (item) => {
    // console.log(item);
    sessionStorage.setItem("match_id", item.id);
    sessionStorage.setItem("game_name", item.name);
    sessionStorage.setItem("team1", item.team1);
    sessionStorage.setItem("team2", item.team2);
    sessionStorage.setItem("type", item.server);
    sessionStorage.setItem("match_type", item.match_type);
    sessionStorage.setItem(
      "session_automatic",
      item.session_automatic ? "true" : "false"
    );
    sessionStorage.setItem("score_code", item.score_code);
    sessionStorage.setItem("CALLED", item.api);
    sessionStorage.setItem("tvcode", item.tv_code);
    sessionStorage.setItem("code_", item.code);
    sessionStorage.setItem("tvcodeapi", item.tv_code_api);

    navigate(`/user-inplay/start-bet?id=${item.id}`);
  };

  return (
    <>
      <div
        className="position-relative userTheme bg-light"
        style={{ overflowX: "hidden" }}
      >
        <div className="position-relative">
          <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
            <PageHeader title="IN PLAY" />
          </div>
          <div className="menu mt-2" id="menu" align="center">
            <ul className="nav">
              <li className="active abc w-100">
                <Link to="/MainMenu" style={{ height: "32.1px" }}>
                  BACK TO MAIN MENU
                </Link>
              </li>
            </ul>
          </div>
          <br />
          <div
            className="row px-0 pt-0"
            style={{ paddingTop: "84px", paddingBottom: "48px" }}
          >
            {liveMatches.length > 0 ? (
              liveMatches?.map((item, index) => (
                <div className="inplylist mb-3" key={`inplay-${index}`}>
                  <Link
                    // to={"/user-start-bet"}
                    // state={item}
                    onClick={(e) => {
                      e.preventDefault();
                      startBet(item);
                    }}
                    className="inplylistLink"
                  >
                    {/* <div className="card" style={{ width: "100%" }}>
                      <div className="teamName">
                        {item.team1} VS {item.team2} ({item.match_type})
                        <p>
                          {dateTimeFormatter(item.server)}{" "}
                          <FontAwesomeIcon
                            icon={faTv}
                            size="s"
                            style={{ color: "#FFD43B" }}
                          />
                        </p>
                      </div>
                    </div> */}
                    <table
                      width="100%"
                      border="0"
                      cellPadding="2"
                      cellSpacing="2"
                      className="table table-bordered align-middle mb-0"
                    >
                      <tbody>
                        <tr bgcolor="white">
                          <td
                            width="30%"
                            height="44"
                            style={{ color: "black" }}
                            className="FontTextWhite10px"
                            id="spantiminginplay"
                          >
                            {item.team1} VS {item.team2} ({item.match_type})
                            <span>
                              {dateTimeFormatter(item.server)}{" "}
                              <FontAwesomeIcon
                                icon={faTv}
                                size="lg"
                                style={{ color: "#FFD43B" }}
                              />
                            </span>
                            <button
                              style={{
                                marginLeft: "10px",
                                backgroundColor: "#28924e",
                                borderRadius: "10px",
                                color: "white",
                                fontSize: "12px",
                              }}
                            >
                              Inplay
                            </button>
                          </td>

                          <td
                            width="5%"
                            className="FontTextWhite10px"
                            id="spaninplayblockresponsive"
                            style={{
                              color: "black",
                              backgroundColor: "#72BBEF",
                            }}
                          >
                            -
                          </td>
                          <td
                            width="5%"
                            className="FontTextWhite10px"
                            id="spaninplayblockresponsive"
                            style={{
                              color: "black",
                              backgroundColor: "#FAA9BA",
                            }}
                          >
                            -
                          </td>
                          <td
                            width="5%"
                            className="FontTextWhite10px"
                            id="spaninplayblockresponsive"
                            style={{
                              color: "black",
                              backgroundColor: "#72BBEF",
                            }}
                          >
                            -
                          </td>
                          <td
                            width="5%"
                            className="FontTextWhite10px"
                            id="spaninplayblockresponsive"
                            style={{
                              color: "black",
                              backgroundColor: "#FAA9BA",
                            }}
                          >
                            -
                          </td>
                          <td
                            width="5%"
                            className="FontTextWhite10px"
                            id="spaninplayblockresponsive"
                            style={{
                              color: "black",
                              backgroundColor: "#72BBEF",
                            }}
                          >
                            -
                          </td>
                          <td
                            width="5%"
                            className="FontTextWhite10px"
                            id="spaninplayblockresponsive"
                            style={{
                              color: "black",
                              backgroundColor: "#FAA9BA",
                            }}
                          >
                            -
                          </td>
                        </tr>
                        <tr id="inplayblockboxresponsive">
                          <td
                            width="5%"
                            className="FontTextWhite10px"
                            style={{
                              color: "black",
                              backgroundColor: "#72BBEF",

                              borderTopLeftRadius: "20px",
                              borderBottomLeftRadius: "20px",
                            }}
                          >
                            -
                          </td>
                          <td
                            width="5%"
                            className="FontTextWhite10px"
                            style={{
                              color: "black",
                              backgroundColor: "#FAA9BA",
                            }}
                          >
                            -
                          </td>
                          <td
                            width="5%"
                            className="FontTextWhite10px"
                            style={{
                              color: "black",
                              backgroundColor: "#72BBEF",
                            }}
                          >
                            -
                          </td>
                          <td
                            width="5%"
                            className="FontTextWhite10px"
                            style={{
                              color: "black",
                              backgroundColor: "#FAA9BA",
                            }}
                          >
                            -
                          </td>
                          <td
                            width="5%"
                            className="FontTextWhite10px"
                            style={{
                              color: "black",
                              backgroundColor: "#72BBEF",
                            }}
                          >
                            -
                          </td>
                          <td
                            width="5%"
                            className="FontTextWhite10px"
                            style={{
                              color: "black",
                              backgroundColor: "#FAA9BA",

                              borderTopRightRadius: "20px",
                              borderBottomRightRadius: "20px",
                            }}
                          >
                            -
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      className="row d-flex flex-column justify-content-center align-items-center py-2 text-black gap-2 text-center shadow-sm"
                      style={{ fontWeight: 500, fontSize: "14px" }}
                    >
                      {/* <div>Match Bets : {item.total_match_bets.length}</div> */}
                      {/* <div>Session Bets : {item.total_session_bets.length}</div> */}
                      {/* <div>Declared : No</div> */}
                      {/* <div>Won by : </div> */}
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <div className="inplylist mb-3">
                <div className="teamName shadow-sm">No live matches found</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",

          bottom: 0,
          width: "100%",

          zIndex: 100,
        }}
      >
        <Footer />
      </div>
    </>
  );
}
