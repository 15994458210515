import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";
import { useState, useEffect } from "react";
import useGetMyLedger from "../hooks/useGetMyLedger";
import { dateTimeFormatter } from "../utils/dateFormatter";
import "./UserPage.css";

import useGetAccountStatus from "./hooks/useGetAccountStatus";

export default function UserMyLedger() {
  let userId = sessionStorage.getItem("UUID");

  const uuid = sessionStorage.getItem("UUID");
  const { mutate: accountStatus } = useGetAccountStatus();

  const { mutate: getMyLedger } = useGetMyLedger();

  const [data, setData] = useState([]);

  const navigate = useNavigate();

  function roundToTwoDecimals(num) {
    return Math.round(num * 100) / 100;
  }

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: "user",
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        console.log(error);
        // toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload = {
      user_id: userId,
      logged_acc_type: "user",
    };

    getMyLedger(payload, {
      onSuccess: (response) => {
        let ledger_data = [];
        let updatedData = [];

        response.data.forEach((element) => {
          if (!ledger_data.some((el) => el.match_id === element.match_id)) {
            ledger_data.push(element);
          } else if (element.match_id === null) {
            ledger_data.push(element);
          } else {
            const index = ledger_data.findIndex(
              (img) => img.match_id === element.match_id
            );
            ledger_data[index].final = ledger_data[index].final + element.final;
          }
        });

        ledger_data.forEach((el) => {
          let won_by = "";

          if (el.games === undefined) {
            updatedData.push({
              date:
                el.match_id === null
                  ? el.createdAt
                  : el.game && el.game.length !== 0
                  ? el.game.updatedAt
                  : el.server,
              name:
                el.game && el.game.length !== 0
                  ? el.game.name
                  : "Cash Collection",
              won_by: won_by,
              final: el.final,
              balance: 0,
            });
          } else {
            updatedData.push({
              date:
                el.match_id === null
                  ? el.createdAt
                  : el.games && el.games.length !== 0
                  ? el.games[0].updatedAt
                  : el.server,
              name:
                el.games && el.games.length !== 0
                  ? el.games[0].name
                  : "Cash Collection",
              won_by: won_by,
              final: el.final,
              balance: 0,
            });
          }
        });

        updatedData.sort((a, b) => {
          return +new Date(b.date) - +new Date(a.date);
        });

        updatedData.reverse();

        updatedData.forEach((ele, index) => {
          if (index - 1 !== -1) {
            updatedData[index].balance =
              updatedData[index - 1].balance + updatedData[index].final;
          } else {
            updatedData[0].balance = updatedData[0].final;
          }
        });

        updatedData.reverse();

        updatedData.forEach((el) => {
          el.final = roundToTwoDecimals(el.final);
          el.balance = roundToTwoDecimals(el.balance);
          el.final = el.final * -1;
          el.balance = el.balance * -1;
        });

        setData(updatedData);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  }, []);

  return (
    <>
      <div className="position-relative userTheme bg-light">
        <div className="position-relative">
          <PageHeader title="LEDGER" />

          <div className="container-fluid table-responsive p-0">
            <table className="ledger table table-striped table-bordered table-hover text-uppercase">
              <thead style={{ background: "#002D5B" }}>
                <tr>
                  <td
                    height="35"
                    colSpan="6"
                    align="center"
                    bgcolor="#3b394a"
                    className="TeamCombo p-0"
                  >
                    <p
                      className="mb-0"
                      style={{
                        color: "#FFF",
                        fontFamily: "Verdana, Geneva, sans-serif",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      MY LEDGER
                    </p>
                  </td>
                </tr>
              </thead>
              <thead style={{ backgroundColor: "#002D5B" }}>
                <tr>
                  <th style={{ width: "600px" }}>Match Name</th>
                  <th>Won</th>
                  <th>Lost</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "600px" }}>
                        {item.name} ({dateTimeFormatter(item.date)})
                      </td>
                      <td className="text-black text-bold">
                        {item.final > 0 ? item.final : 0}
                      </td>
                      <td className="text-black text-bold">
                        {item.final < 0 ? item.final : 0}
                      </td>
                      <td
                        className={`text-bold ${
                          item.balance > 0 ? "text-black" : "text-black"
                        }`}
                      >
                        {item.balance}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          marginTop: "20px",

          zIndex: 100,
        }}
      >
        <Footer />
      </div>
    </>
  );
}
