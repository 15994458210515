import { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";

const useSocketUserdata = (userId) => {
  const [data, setData] = useState(null);
  const socketRef = useRef(null);

  useEffect(() => {
    if (
      userId === 0 ||
      userId === null ||
      userId === undefined ||
      userId === ""
    ) {
      return;
    }

    socketRef.current = io("https://api.acebets.in");

    const handleConnect = () => {
      console.log("Connected to server");

      socketRef.current.emit("user_data", {
        userId: userId,
      });

      const handleUserData = (userData) => {
        setData(userData);
      };

      socketRef.current.on(`user_data_${userId}`, handleUserData);

      return () => {
        socketRef.current.off(`user_data_${userId}`, handleUserData);
      };
    };

    const handleError = (error) => {
      console.error("Socket error: ", error);
    };

    socketRef.current.on("connect", handleConnect);
    socketRef.current.on("error", handleError);

    return () => {
      socketRef.current.disconnect();
      socketRef.current.off("connect", handleConnect);
      socketRef.current.off("error", handleError);
    };
  }, [userId]);

  return data;
};

export default useSocketUserdata;
